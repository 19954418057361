import { css } from '@emotion/react'
import { colorList } from './color'

export const styleTable = css`
  table {
    width: 100%;
    table-layout: fixed;
  }

  table,
  th,
  td {
    border-collapse: collapse;
    border-top: 1px solid ${colorList.border10};
    border-bottom: 1px solid ${colorList.border10};
  }

  th {
    width: 220px;
    background-color: ${colorList.bg10};
    padding: 24px 32px;
    font-size: 16px;
    line-height: 1.7;
    text-align: left;
    vertical-align: top;
  }
  td {
    padding: 24px;
    font-size: 16px;
    line-height: 1.7;
  }

  @media screen and (max-width: 1199px) {
    th {
      width: 120px;
      padding: 24px 16px;
      font-size: 14px;
    }
    td {
      padding: 24px 16px;
    }
  }
`
